import React from "react";
import PropTypes from "prop-types";
import Layout from "../components/layout";
import SEO from "../components/seo";

// Components
import { Link, graphql } from "gatsby";

// Utilities
import kebabCase from "lodash/kebabCase";

const Tags = ({ data, pageContext, location }) => {
	const siteTitle = data.site.siteMetadata.title;
	const { tag } = pageContext;
	const totalCount = data.allMarkdownRemark.totalCount;
	const posts = data.allMarkdownRemark.nodes;

	const tagHeader = `${totalCount} post${
		totalCount === 1 ? "" : "s"
	} tagged with "${tag}"`;

	return (
		<Layout location={location} title={siteTitle}>
			<SEO title={tag} />
			<h2 className="tags-heading">{tagHeader}</h2>
			<ol style={{ listStyle: `none` }}>
				{posts.map((post) => {
					const title = post.frontmatter.title || post.fields.slug;

					return (
						<li key={post.fields.slug}>
							<article
								className="post-list-item"
								itemScope
								itemType="http://schema.org/Article"
							>
								<header>
									<h2>
										<Link to={post.fields.slug} itemProp="url" title={title}>
											<span itemProp="headline">{title}</span>
										</Link>
									</h2>
									<small>{post.frontmatter.date}</small>
								</header>
								<section>
									{post.frontmatter.thumbnail && (
										<div className="post-list-thumbnail">
											<img src={post.frontmatter.thumbnail} alt={title} />
										</div>
									)}
									<div className="post-list-content">
										<p
											dangerouslySetInnerHTML={{
												__html: post.frontmatter.description || post.excerpt,
											}}
											itemProp="description"
										/>
										{post.frontmatter.tags && (
											<ul>
												{post.frontmatter.tags.map((tag) => {
													return (
														<li key={tag}>
															<a href={`/tags/${kebabCase(tag)}/`} title={tag}>
																{tag}
															</a>
														</li>
													);
												})}
											</ul>
										)}
									</div>
								</section>
							</article>
						</li>
					);
				})}
			</ol>
			<Link to="/tags">All tags</Link>
		</Layout>
	);
};

Tags.propTypes = {
	pageContext: PropTypes.shape({
		tag: PropTypes.string.isRequired,
	}),
	data: PropTypes.shape({
		allMarkdownRemark: PropTypes.shape({
			totalCount: PropTypes.number.isRequired,
			edges: PropTypes.arrayOf(
				PropTypes.shape({
					node: PropTypes.shape({
						frontmatter: PropTypes.shape({
							title: PropTypes.string.isRequired,
						}),
						fields: PropTypes.shape({
							slug: PropTypes.string.isRequired,
						}),
					}),
				}).isRequired
			),
		}),
	}),
};

export default Tags;

export const pageQuery = graphql`
	query($tag: String) {
		site {
			siteMetadata {
				title
			}
		}
		allMarkdownRemark(
			limit: 2000
			sort: { fields: [frontmatter___date], order: DESC }
			filter: { frontmatter: { tags: { in: [$tag] } } }
		) {
			totalCount
			nodes {
				excerpt
				fields {
					slug
				}
				frontmatter {
					date(formatString: "MMMM DD, YYYY")
					title
					category
					description
					tags
					thumbnail
					writer
				}
			}
		}
	}
`;
