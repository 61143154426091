import React, { useState, useEffect } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight, faArrowAltCircleLeft } from "@fortawesome/free-regular-svg-icons";

// Utilities
import kebabCase from "lodash/kebabCase";

const BlogIndex = ({ data, location }) => {
	const siteTitle = data.site.siteMetadata.title;
	const posts = data.allMarkdownRemark.nodes;
	const [pageNum, setPageNum] = useState(1);
	const [showingPosts, setShowingPosts] = useState([]);
	const [showDate, setShowDate] = useState("");

	useEffect(() => {
		let tempPosts = posts.slice((pageNum - 1) * 6, pageNum * 6);
		setShowingPosts(tempPosts);
	}, [pageNum]);

	if (posts.length === 0) {
		return (
			<Layout location={location} title={siteTitle}>
				<SEO title="메인" reverse={false} />
				<p>No blog posts found.</p>
			</Layout>
		);
	}

	return (
		<Layout location={location} title={siteTitle}>
			<SEO title="메인" reverse={false} />
			<div className="post-list-contaner" id="test">
				<ol style={{ listStyle: `none` }}>
					{showingPosts.map((post) => {
						const title = post.frontmatter.title || post.fields.slug;
						const month = post.frontmatter.date.split(",")[0].split(" ")[0];
						const day = post.frontmatter.date.split(",")[0].split(" ")[1];

						return (
							<li
								key={post.fields.slug}
								onMouseEnter={() => {
									setShowDate(post.fields.slug);
								}}
								onMouseLeave={() => {
									setShowDate("");
								}}
							>
								<article
									className="post-list-item"
									itemScope
									itemType="http://schema.org/Article"
								>
									<header>
										<h2>
											<Link to={post.fields.slug} itemProp="url" title={title}>
												<span itemProp="headline">{title}</span>
											</Link>
										</h2>
										<small>{post.frontmatter.date}</small>
									</header>
									<section>
										<div className="post-list-thumbnail-wrapper">
											<div
												className={`post-list-date ${
													showDate === post.fields.slug ||
													!post.frontmatter.thumbnail
														? "on"
														: ""
												}`}
											>
												{day}
												<span>{month}</span>
											</div>
											{post.frontmatter.thumbnail && (
												<div
													className={`post-list-thumbnail ${
														showDate !== post.fields.slug ? "on" : ""
													}`}
												>
													<img src={post.frontmatter.thumbnail} alt={title} />
												</div>
											)}
										</div>
										<div className="post-list-content">
											<p
												dangerouslySetInnerHTML={{
													__html: post.frontmatter.description || post.excerpt,
												}}
												itemProp="description"
											/>
											{post.frontmatter.tags && (
												<ul>
													{post.frontmatter.tags.map((tag) => {
														return (
															<li key={tag}>
																<a
																	href={`/tags/${kebabCase(tag)}/`}
																	title={tag}
																>
																	{tag}
																</a>
															</li>
														);
													})}
												</ul>
											)}
										</div>
									</section>
								</article>
							</li>
						);
					})}
				</ol>
				<div className="page-wrapper">
					<button
						className="page-btn prev"
						disabled={pageNum === 1}
						onClick={() => {
							if (pageNum !== 1) {
								setPageNum((prev) => {
									return prev - 1;
								});
							}
						}}
					>
						<FontAwesomeIcon icon={faArrowAltCircleLeft} />
					</button>
					{pageNum} / {Math.ceil(posts.length / 6)}
					<button
						className="page-btn next"
						disabled={pageNum === Math.ceil(posts.length / 6)}
						onClick={() => {
							if (pageNum !== Math.ceil(posts.length / 6)) {
								setPageNum((prev) => {
									return prev + 1;
								});
							}
						}}
					>
						<FontAwesomeIcon icon={faArrowAltCircleRight} />
					</button>
				</div>
			</div>
		</Layout>
	);
};

export default BlogIndex;

export const pageQuery = graphql`
	query {
		site {
			siteMetadata {
				title
			}
		}
		allMarkdownRemark(
			sort: { fields: [frontmatter___date], order: DESC }
			filter: { frontmatter: { category: { ne: null }, draft: { eq: false } } }
		) {
			nodes {
				excerpt
				fields {
					slug
				}
				frontmatter {
					date(formatString: "MMMM DD, YYYY")
					title
					category
					description
					tags
					thumbnail
					writer
				}
			}
		}
	}
`;
