module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://blog.nextinnovation.co.kr"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-195251321-1","head":true,"anonymize":true,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"className":"anchor-header","maintainCase":false,"removeAccents":true,"elements":["h1","h2","h3","h4","h5"],"offsetY":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"넥스트이노베이션 공식 블로그","short_name":"넥스트이노베이션 공식 블로그","start_url":"/","background_color":"#ffffff","display":"minimal-ui","icon":"content/assets/app-icon.png","icons":[{"src":"./icons/icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"./icons/icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"./icons/icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"./icons/icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"./icons/icon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"./icons/icon-256x256.png","sizes":"256x256","type":"image/png"},{"src":"./icons/icon-384x384.png","sizes":"384x384","type":"image/png"},{"src":"./icons/icon-512x512.png","sizes":"512x512","type":"image/png"},{"src":"./icons/icon-1024x1024.png","sizes":"1024x1024","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"13bc4e9ab93607418b22359ce219f687"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"enableIdentityWidget":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-page-progress/gatsby-browser.js'),
      options: {"plugins":[],"height":3,"prependToBody":false,"color":"#000000","footerHeight":50,"includePaths":[{"regex":"^/blog"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
